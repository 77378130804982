export const SITE_URI = process.env.REACT_APP_SITE_URI;
export const BASE_API = process.env.REACT_APP_BASE_API || "https://api.meunovoapp.com.br/v1";
export const BASE_WS = process.env.REACT_APP_BASE_WS || "https://api.meunovoapp.com.br";
export const BASE_FILES = process.env.REACT_APP_BASE_FILES || "https://api.meunovoapp.com.br/files";

export const SENDER_EMAIL = process.env.REACT_APP_SENDER_EMAIL;

export const headers = {
  "Content-Type": "application/json",
};
